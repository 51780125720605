import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { syncSvenskaLag } from "../../util/apicalls";

const styles = (theme) => ({
  headline: {
    textTransform: "uppercase",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    color: theme.palette.text.primary,
  },
});

class SyncCalendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statusMsg: "",
    };

    this.sync = this.sync.bind(this);
  }

  sync(trainingGroup) {
    this.setState({ statusMsg: "Synkar " + trainingGroup + "..." });

    syncSvenskaLag(trainingGroup).then((response) => {
      if (response.status === 200) {
        this.setState({ statusMsg: response.data });
      } else {
        this.setState({ statusMsg: "Något gick fel!" });
      }
    });
  }

  render() {
    return (
      <Paper className={this.props.classes.paper}>
        <Typography variant="h2">Synka från Svenska Lag</Typography>
        <Typography variant="h3">{this.state.statusMsg}</Typography>
        <Typography variant="body2">Aktivitet med texten (X) i "plats" kommer inte synkas över</Typography>

        <Button
          variant="contained"
          color="primary"
          className={this.props.classes.button}
          onClick={() => this.sync("white")}
        >
          SYNKA VIT GRUPP
        </Button>
        <br />
        <br />
        <Button
          variant="contained"
          color="primary"
          className={this.props.classes.button}
          onClick={() => this.sync("yellow")}
        >
          SYNKA GUL GRUPP
        </Button>
        <br />
        <br />
        <Button
          variant="contained"
          color="primary"
          className={this.props.classes.button}
          onClick={() => this.sync("orange")}
        >
          SYNKA ORANGE GRUPP
        </Button>
        <br />
        <br />
        <Button
          variant="contained"
          color="primary"
          className={this.props.classes.button}
          onClick={() => this.sync("violet")}
        >
          SYNKA VIOLETT GRUPP
        </Button>
        <br />
        <br />
        <Button
          variant="contained"
          color="primary"
          className={this.props.classes.button}
          onClick={() => this.sync("black")}
        >
          SYNKA SVART GRUPP
        </Button>
        <br />
        <br />
      </Paper>
    );
  }
}

export default withStyles(styles)(SyncCalendar);
