import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  bullet: {
    display: "block",
    paddingLeft: theme.spacing(0),
    marginBottom: theme.spacing(0),
    "&::before": {
      content: `'• '`,
    },
  },
  link: {
    textTransform: "none",
    minWidth: "auto",
    minHeight: "auto",
    padding: 0,
  },
  title: {
    marginTop: theme.spacing(1),
  },
});

class DynamicContent extends Component {
  constructor(props) {
    super(props);

    this.index = 0;
    this.setData = this.getData.bind(this);

    this.state = {
      children: this.getData(),
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.delta || this.props.delta.ops !== prevProps.delta.ops) {
      this.setState({ children: this.getData() });
    }
  }

  getData() {
    var items = [];
    var content = [];

    if (!this.props.delta || !this.props.delta.ops) {
      return;
    }

    this.props.delta.ops.forEach(function (op) {
      if (op.attributes && op.attributes.header) {
        if (op.attributes.header === 4) {
          items.push(this.getContent(content));
          content.push({ type: "br" });
        } else {
          items.push(this.getHeader(op.attributes.header, content));
          content.push({ type: "br" });
        }

        content = [];
      } else if (op.attributes && op.attributes.list) {
        items.push(this.getBullet(content));
        content = [];
      } else if (op.attributes && op.attributes.link) {
        content.push({
          type: "link",
          text: op.insert,
          link: op.attributes.link,
        });
      } else {
        var contentArray = op.insert.split("\n");

        contentArray.forEach(function (text) {
          content.push({ type: "text", text: text });
          content.push({ type: "br" });
        }, this);
      }
    }, this);

    if (content.length > 0) {
      items.push(this.getContent(content));
    }

    return items;
  }

  getHeader(headerType, content) {
    return (
      <Typography
        key={this.index++}
        variant={headerType === 2 ? "h2" : "h3"}
        className={this.props.classes.title}
      >
        {content.map(function (item) {
          if (item.type === "text") {
            return <span key={this.index++}>{item.text}</span>;
          } else if (item.type === "br") {
            return <br key={this.index++} />;
          } else {
            return this.getLink(item.link, item.text);
          }
        }, this)}
      </Typography>
    );
  }

  getLink(link, content) {
    if (link.startsWith("###")) {
      /* internal link */
      link = `https://skogshjortarna-cms.azurewebsites.net/files/${link.substring(
        3
      )}`;
    }

    return (
      <Button
        key={this.index++}
        color="secondary"
        className={this.props.classes.link}
        href={link}
      >
        {content}
      </Button>
    );
  }

  getBullet(content) {
    return (
      <Typography
        key={this.index++}
        variant="body1"
        className={this.props.classes.bullet}
      >
        {content.map(function (item) {
          if (item.type === "text") {
            return <span key={this.index++}>{item.text}</span>;
          } else if (item.type === "br") {
            return <br key={this.index++} />;
          } else {
            return this.getLink(item.link, item.text);
          }
        }, this)}
      </Typography>
    );
  }

  getContent(content) {
    return (
      <Typography key={this.index++} variant="body1" gutterBottom>
        {content.map(function (item) {
          if (item.type === "text") {
            return <span key={this.index++}>{item.text}</span>;
          } else if (item.type === "br") {
            return <br key={this.index++} />;
          } else {
            return this.getLink(item.link, item.text);
          }
        }, this)}
      </Typography>
    );
  }

  render() {
    return <div>{this.state.children}</div>;
  }
}

export default withStyles(styles)(DynamicContent);
